// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-regulamin-sali-zabaw-js": () => import("./../../../src/pages/regulamin-sali-zabaw.js" /* webpackChunkName: "component---src-pages-regulamin-sali-zabaw-js" */),
  "component---src-pages-regulamin-urodzin-js": () => import("./../../../src/pages/regulamin-urodzin.js" /* webpackChunkName: "component---src-pages-regulamin-urodzin-js" */),
  "component---src-pages-urodziny-js": () => import("./../../../src/pages/urodziny.js" /* webpackChunkName: "component---src-pages-urodziny-js" */),
  "component---src-pages-wnetrze-js": () => import("./../../../src/pages/wnetrze.js" /* webpackChunkName: "component---src-pages-wnetrze-js" */)
}

